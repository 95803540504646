<template>

    <div class="page page-sidebar bg-4">
        <notifications/>
        <lego-side-bar drop-down-animation="fade" :items="menu"
                       :logo-text="'Internal Project'" menu-list-ul-classes="mt-5"
                       :colored="false" has-separator>
            <template #logo>
                <img src="../assets/img/logo/Xeo_Logo_Files/Logo/Xeo_Logo-White.png" class="my-4" alt=""
                     @click="redirectHome()">
            </template>
        </lego-side-bar>
        <div class="page-content-outer">
            <!--            </lego-nav-bar>-->
            <div class="pos-ft bg-3 z-index-md">
                <lego-nav-bar logo-text="" drop-down-animation="fade" hover-effect="0" @link-event="linkEvent"
                              class="headerFixed z-index-max nav container-fluid w-100p text-white bg-2" shadow="0"
                              :items="menu1"/>
            </div>
            <div class="page-content py-5 px-3">
                <div class="thin-scrollbar">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>
            </div>
            <div class="bg-primary-900 p-3">
                © {{ year }} Admin
            </div>
        </div>
        <modal ref="password" :show-additional-buttons="true" title=""
               width="30r" no-close-on-backdrop>
            <change-password @cancel="closeModal"></change-password>
        </modal>
        <custom-modal ref="notifications" header-color="primary"
                      title="Notifications" width="20r" no-close-on-backdrop>
            <div>
                <div v-if="loading" class="text-center">
                    <loading-animation/>
                    <br>
                    <b>Please Wait...</b>
                </div>
                <div v-else>
                    <div v-for="(item, index) in notificationData" :key="index" class="mt-2 bb-3">
                        <h6 class="text-primary">{{item.title}}</h6>
                        <span class="text-black-50 mb-3">
                            {{item.description}}
                        </span>
                    </div>
                </div>
            </div>
        </custom-modal>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoSideBar from 'lego-framework/src/components/side-navbar-colored/SideNavBarColored';
import getSideMenu from '../data/menu';
import getTopNavMenu from '../data/navMenu';
import ChangePassword from '../views/auth/ChangePassword';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    components: {
        ChangePassword,
        LegoNavBar,
        LegoSideBar
    },
    data () {
        return {
            menu: getSideMenu(),
            menu1: getTopNavMenu(),
            value: '',
            year: new Date().getFullYear(),
            notificationData: [],
            loading: false
        };
    },
    methods: {
        closeModal () {
            this.$refs.password.close();
        },
        linkEvent (link) {
            if (link === 'changePassword') {
                this.$refs.password.show();
            }
            if (link === 'notifications') {
                this.loadNotification();
                this.$refs.notifications.show();
            }
        },
        redirectHome () {
            this.$router.push({ path: '/' });
        },

        async loadNotification () {
            this.loading = true;
            const response = await axios.get(urls.Notification.List);
            console.log('Response:', response);
            this.notificationData = response.data.data;
            this.loading = false;
        }
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

</style>

import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'fa fa-bell text-danger',
            text: 'Notifications',
            class: 'text-danger',
            event: 'notifications'
        },

        {
            icon: 'fa fa-user mr-3',
            class: 'ml-auto',
            text: user.username,

            // link: '/login/',
            items: [
                {
                    text: 'Logout',
                    link: '/logout/'
                },
                /* {
                    text: 'Change Password',
                    link: '/change-password/'
                } */{
                    text: 'Change Password',
                    event: 'changePassword'
                }

                /* {
                    text: 'Update Password',
                    link: '/update-password/'
                } */
            ]
        }

    ];

    const adminMenu = [
        {
            icon: 'fa fa-bell text-danger',
            text: 'Notifications',
            class: 'text-danger',
            event: 'notifications'
        },
        {
            icon: 'fa fa-user mr-3',
            class: 'ml-auto',
            text: user.username,

            // link: '/login/',
            items: [
                {
                    text: 'Logout',
                    link: '/logout/'
                },
                /* {
                    text: 'Change Password',
                    link: '/change-password/'
                } */{
                    text: 'Change Password',
                    event: 'changePassword'
                }

                /* {
                    text: 'Update Password',
                    link: '/update-password/'
                } */
            ]
        }
    ];

    // Do something similar for any number of roles

    if (user && user.admin === true) {
        return [
            ...adminMenu

        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;

import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'ni ni-user-run',
            text: 'Dashboard',
            link: '/'
        },
        /* { */
        /* text: 'Master Data',
            link: '#', */

        // {
        //     text: 'Home',
        //     link: '/'
        // },
        /* {
            text: 'User',
            link: '/User-list/'
        }, */
        {
            icon: 'fa fa-th-list',
            text: 'Projects',
            link: '/projects/'
        }

        /* } */
    ];

    const adminMenu = [
        {
            icon: 'ni ni-user-run',
            text: 'Dashboard',
            link: '/'
        },

        {
            icon: 'fa fa-users',
            text: 'Users',
            link: '/users/'
        },
        {
            icon: 'fa fa-th-list',
            text: 'Projects',
            link: '/projects/'
        },
        {
            icon: 'fa fa-bell',
            text: 'Notifications',
            link: '/notifications/'
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
